import React, { useState, Suspense, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import blogData from "../data/blogs.json"; // Import your JSON data
// import { themeContext } from '../Context';
import { Helmet } from 'react-helmet';

function BlogPage() {
    const { blogType, blogId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedPost, setSelectedPost] = useState(null);
    const [DynamicComponent, setDynamicComponent] = useState(null);
    // const theme = useContext(themeContext);
    // const darkMode = theme.state.darkMode;

    const showHeading = location.pathname === `/blogsCategory/` || `/blogsCategory`;

    // Find the selected category and subcategory
    const selectedCategory = blogData.categories.find(category => category.category === blogType);
    const selectedSubCategory = selectedCategory && blogId !== undefined ? selectedCategory.subCategories[parseInt(blogId)] : null;

    // Handle post selection and dynamically import the component
    // const handlePostClick = async (postId) => {
    //     const post = selectedSubCategory.pages.find(page => page.id === postId);
    //     if (post) {
    //         try {
    //             const module = await import(`../${post.filepath}`);
    //             setDynamicComponent(() => module.default);
    //             setSelectedPost(post);
    //         } catch (error) {
    //             console.error("Error loading the page component:", error);
    //         }
    //     }
    // };
    const handlePostClick = async (postId, fileName) => {
        const post = selectedSubCategory.pages.find(page => page.id === postId);
        if (post) {
            try {
                // Navigate to the new route including blogId and fileName
                navigate(`/blogsCategory/${blogType}/${blogId}/${fileName}`);
                const module = await import(`../${post.filepath}`);
                setDynamicComponent(() => module.default);
                setSelectedPost(post);
            } catch (error) {
                console.error("Error loading the page component:", error);
            }
        }
    };

    useEffect(() => {
        if (location.pathname !== `/blogsCategory/${blogType}/${blogId}`) {
            setSelectedPost(null);
            setDynamicComponent(null);
        }
    }, [location.pathname, blogType, blogId]);

    useEffect(() => {
        if (location.pathname === `/blogsCategory/${blogType}/${blogId}`) {
            setSelectedPost(null);
            setDynamicComponent(null);
        } else if (blogType && blogId && location.pathname.includes(`/blogsCategory/${blogType}/${blogId}/`)) {
            // Logic to maintain the post display state if the route matches a blog post
            const matchingPost = selectedSubCategory?.pages.find(
                page => page.fileName === location.pathname.split('/').pop()
            );
            if (matchingPost && !selectedPost) {
                (async () => {
                    try {
                        const module = await import(`../${matchingPost.filepath}`);
                        setDynamicComponent(() => module.default);
                        setSelectedPost(matchingPost);
                    } catch (error) {
                        console.error("Error loading the post component:", error);
                    }
                })();
            }
        }
    }, [location.pathname, blogType, blogId, selectedSubCategory, selectedPost]);


    return (
        <div style={{ padding: '20px', minHeight: "100vh", overflowY: 'auto' }}>
            <Helmet>
                <title>Informative Blog Page | Topics and Insights</title>
                <meta name="description" content="Explore various blog topics and insights on different categories." />
                <meta name="keywords" content="blogs, categories, topics, informative articles" />
            </Helmet>

            {showHeading && (
                <div className="t-heading" style={{ marginBottom: "15px" }}>
                    <span>Informative Blog Page </span>
                    <span>Explore Various Topics and Insights</span>
                </div>
            )}

            {/* <div className="t-heading">
                <span>{blogType ? `${blogType.charAt(0).toUpperCase() + blogType.slice(1)} Articles` : 'Select a Category:'}</span>
            </div> */}


            {/* Category Selection */}
            {!blogType && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px', flexWrap: "wrap" }}>
                    {blogData.categories.map((category) => (
                        <div
                            key={category.category}
                            onClick={() => navigate(`/blogsCategory/${category.category}`)}
                            style={{
                                cursor: 'pointer',
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                width: '200px',
                                textAlign: 'center',
                                padding: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <img
                                src={category.imageURL}
                                alt={category.category}
                                style={{ width: '100%', borderRadius: '10px' }}
                            />
                            <p style={{ fontWeight: 'bold', marginTop: '10px' }}>{category.category.charAt(0).toUpperCase() + category.category.slice(1)}</p>
                        </div>
                    ))}
                </div>
            )}

            {/* Subcategory Selection */}
            {blogType && blogId === undefined && selectedCategory && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px', flexWrap: "wrap" }}>
                    {selectedCategory.subCategories.map((subCat, index) => (
                        <div
                            key={subCat.title}
                            onClick={() => navigate(`/blogsCategory/${blogType}/${index}`)}
                            style={{
                                cursor: 'pointer',
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                width: '200px',
                                textAlign: 'center',
                                padding: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <p style={{ fontWeight: 'bold', marginTop: '10px' }}>
                                {subCat.title} (Posts: {subCat.pages.length})
                            </p>
                        </div>
                    ))}
                </div>
            )}

            {/* Display Blog Posts */}

            {!selectedPost && selectedSubCategory && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px', flexWrap: "wrap" }}>
                    {selectedSubCategory.pages.map((page) => (
                        <div key={page.id} style={{
                            cursor: 'pointer',
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            width: '200px',
                            textAlign: 'center',
                            padding: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        }} onClick={() => handlePostClick(page.id, page.fileName)}>
                            <p
                                style={{ cursor: 'pointer', fontWeight: 'bold', marginTop: '10px' }}>
                                {/* {page.fileName}  */}
                                {page.fileTitle}</p>
                        </div>
                    ))}
                </div>
            )}



            {/* Display Full Post */}
            {selectedPost && DynamicComponent && (
                <div style={{ marginTop: '20px' }}>
                    <Suspense fallback={<div>Loading post...</div>}>
                        <DynamicComponent />
                    </Suspense>
                    {/* <button className="button" onClick={() => {
                        setSelectedPost(null);
                        setDynamicComponent(null);
                    }}>Back to Posts</button> */}
                    <button className="button" style={{ marginTop: "10px" }} onClick={() => navigate(`/blogsCategory/${blogType}/${blogId}`)}>
                        Back to Posts
                    </button>

                </div>
            )}


        </div>
    );
}

export default BlogPage;
