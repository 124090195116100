import React, { useContext, useState, useEffect } from "react";
import { toPng } from "html-to-image";
// import { saveAs } from "file-saver";
import { themeContext } from "../Context";
import Whammy from 'react-whammy';

const SharePopupWithVideo = ({ question, onClose }) => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    // const handleDownloadVideo = async () => {
    //     const element = document.getElementById(`share-question-${question.id}`);
    //     if (!element) {
    //         console.error("Element not found");
    //         return;
    //     }

    //     setIsProcessing(true);

    //     const { width, height } = element.getBoundingClientRect(); // Get element's current dimensions
    //     const aspectRatio = width / height;

    //     // Adjust canvas size proportionally
    //     const canvasWidth = 300; // Example base width
    //     const canvasHeight = canvasWidth / aspectRatio;

    //     try {


    //         // Step 1: Generate an image of the div content
    //         const dataUrl = await toPng(element, {
    //             quality: 1,
    //             canvasWidth,
    //             canvasHeight,
    //         });

    //         // Convert the DataURL to a Blob
    //         const imageBlob = await fetch(dataUrl).then((res) => res.blob());
    //         const imageFile = new File([imageBlob], "frame.png", { type: "image/png" });

    //         // Step 2: Lazy load FFmpeg
    //         const { FFmpeg } = await import("@ffmpeg/ffmpeg");
    //         const ffmpeg = new FFmpeg();

    //         // Step 3: Initialize FFmpeg
    //         await ffmpeg.load();

    //         // Step 4: Write the image file to FFmpeg
    //         await ffmpeg.writeFile("frame.png", new Uint8Array(await imageFile.arrayBuffer()));

    //         // Step 5: Create the video
    //         await ffmpeg.exec([
    //             "-loop",
    //             "1", // Use the image as a frame
    //             "-i",
    //             "frame.png", // Input file
    //             "-c:v",
    //             "libx264", // Codec
    //             "-t",
    //             "5", // Video duration in seconds
    //             "-pix_fmt",
    //             "yuv420p", // Pixel format for compatibility
    //             "output.mp4",
    //         ]);

    //         // Step 6: Read the generated video file
    //         const videoData = await ffmpeg.readFile("output.mp4");

    //         // Step 7: Create a downloadable URL for the video
    //         const videoBlob = new Blob([videoData.buffer], { type: "video/mp4" });
    //         const videoUrl = URL.createObjectURL(videoBlob);

    //         // Step 8: Trigger the download
    //         saveAs(videoUrl, `question-${question.id}.mp4`);
    //         alert("Video downloaded successfully!");
    //     } catch (error) {
    //         console.error("Error generating video:", error);
    //         alert("An error occurred while generating the video.");
    //     } finally {
    //         setIsProcessing(false);
    //     }
    // };



    //correct/incorrect answer highlighting feature

    //NEWER VERSION 001
    // const handleDownloadVideo = async () => {

    //     // Clear any previous data and reset states
    //     setIsProcessing(false);
    //     const element = document.getElementById(`share-question-${question.id}`);
    //     if (!element) {
    //         console.error("Element not found");
    //         return;
    //     }

    //     setIsProcessing(true);

    //     const { width, height } = element.getBoundingClientRect(); // Get dimensions
    //     const aspectRatio = width / height;

    //     const canvasWidth = 300; // Example base width
    //     const canvasHeight = canvasWidth / aspectRatio;

    //     try {
    //         // Step 1: Generate the first image (question only)
    //         const questionDataUrl = await toPng(element, {
    //             quality: 1,
    //             canvasWidth,
    //             canvasHeight,
    //         });

    //         // Step 2: Highlight the correct answer (modify styles dynamically)
    //         const options = element.querySelectorAll("li");
    //         options.forEach((optionElement) => {
    //             const option = optionElement.textContent.trim();
    //             if (option === question.correctAnswer) {
    //                 optionElement.style.backgroundColor = "lightgreen"; // Highlight correct answer
    //                 optionElement.style.color = "white"; // Improve visibility
    //             }
    //         });

    //         // Step 3: Generate the second image (question + highlighted correct answer)
    //         const answerDataUrl = await toPng(element, {
    //             quality: 1,
    //             canvasWidth,
    //             canvasHeight,
    //         });

    //         // Step 4: Reset the styles to avoid affecting the original DOM
    //         options.forEach((optionElement) => {
    //             optionElement.style.backgroundColor = ""; // Reset background
    //             optionElement.style.color = ""; // Reset color
    //         });

    //         // Convert DataURLs to Blobs and Files
    //         const questionImageBlob = await fetch(questionDataUrl).then((res) => res.blob());
    //         const questionImageFile = new File([questionImageBlob], "question.png", { type: "image/png" });

    //         const answerImageBlob = await fetch(answerDataUrl).then((res) => res.blob());
    //         const answerImageFile = new File([answerImageBlob], "answer.png", { type: "image/png" });

    //         // Step 5: Lazy load FFmpeg
    //         const { FFmpeg } = await import("@ffmpeg/ffmpeg");
    //         const ffmpeg = new FFmpeg();

    //         // Step 6: Initialize FFmpeg
    //         await ffmpeg.load();

    //         // Step 7: Write the image files to FFmpeg
    //         await ffmpeg.writeFile("question.png", new Uint8Array(await questionImageFile.arrayBuffer()));
    //         await ffmpeg.writeFile("answer.png", new Uint8Array(await answerImageFile.arrayBuffer()));

    //         // Step 8: Create the video with two segments (question: 5s, answer: 5s)
    //         await ffmpeg.exec([
    //             "-loop", "1", "-t", "10", "-i", "question.png", // 5s of question only
    //             "-loop", "1", "-t", "5", "-i", "answer.png",   // 5s of question + answer

    //             // Combine them
    //             "-filter_complex", "[0:v:0][1:v:0]concat=n=2:v=1:a=0[out]",
    //             // "-filter_complex", "[0:v][1:v]xfade=transition=fade:duration=2:offset=5[out]",

    //             "-map", "[out]",

    //             // Output settings
    //             "-c:v", "libx264",
    //             "-pix_fmt", "yuv420p",
    //             "output.mp4",
    //         ]);

    //         // Step 9: Read the generated video file
    //         const videoData = await ffmpeg.readFile("output.mp4");

    //         // Step 10: Create a downloadable URL for the video
    //         const videoBlob = new Blob([videoData.buffer], { type: "video/mp4" });
    //         const videoUrl = URL.createObjectURL(videoBlob);

    //         // Step 11: Trigger the download
    //         saveAs(videoUrl, `question-${question.id}.mp4`);
    //         alert("Video downloaded successfully!");
    //     } catch (error) {
    //         console.error("Error generating video:", error);
    //         alert("An error occurred while generating the video.");
    //     } finally {
    //         setIsProcessing(false);
    //     }
    // };

    //NEWER VERSION 002
    // const handleDownloadVideo = async () => {
    //     // Clear any previous data and reset states
    //     setIsProcessing(false);

    //     const element = document.getElementById(`share-question-${question.id}`);
    //     if (!element) {
    //         console.error("Element not found");
    //         return;
    //     }

    //     setIsProcessing(true);

    //     const { width, height } = element.getBoundingClientRect();
    //     const aspectRatio = width / height;

    //     const canvasWidth = 300;
    //     const canvasHeight = canvasWidth / aspectRatio;

    //     try {
    //         // Clear old FFmpeg files
    //         if (window.ffmpegInstance) {
    //             window.ffmpegInstance = null;
    //         }

    //         // Step 1: Generate the first image (question only)
    //         const questionDataUrl = await toPng(element, {
    //             quality: 1,
    //             canvasWidth,
    //             canvasHeight,
    //         });

    //         // Step 2: Highlight the correct answer (modify styles dynamically)
    //         const options = element.querySelectorAll("li");
    //         options.forEach((optionElement) => {
    //             const option = optionElement.textContent.trim();
    //             if (option === question.correctAnswer) {
    //                 optionElement.style.backgroundColor = "lightgreen";
    //                 optionElement.style.color = "white";
    //             }
    //         });

    //         // Step 3: Generate the second image (question + highlighted correct answer)
    //         const answerDataUrl = await toPng(element, {
    //             quality: 1,
    //             canvasWidth,
    //             canvasHeight,
    //         });

    //         // Step 4: Reset the styles to avoid affecting the original DOM
    //         options.forEach((optionElement) => {
    //             optionElement.style.backgroundColor = "";
    //             optionElement.style.color = "";
    //         });

    //         // Convert DataURLs to Blobs and Files
    //         const questionImageBlob = await fetch(questionDataUrl).then((res) => res.blob());
    //         const questionImageFile = new File([questionImageBlob], "question.png", { type: "image/png" });

    //         const answerImageBlob = await fetch(answerDataUrl).then((res) => res.blob());
    //         const answerImageFile = new File([answerImageBlob], "answer.png", { type: "image/png" });

    //         // Step 5: Lazy load FFmpeg
    //         const { FFmpeg } = await import("@ffmpeg/ffmpeg");
    //         const ffmpeg = new FFmpeg();

    //         // Initialize FFmpeg instance for later use
    //         window.ffmpegInstance = ffmpeg;

    //         // Step 6: Initialize FFmpeg
    //         await ffmpeg.load();

    //         // Step 7: Write the image files to FFmpeg
    //         await ffmpeg.writeFile("question.png", new Uint8Array(await questionImageFile.arrayBuffer()));
    //         await ffmpeg.writeFile("answer.png", new Uint8Array(await answerImageFile.arrayBuffer()));

    //         // Step 8: Create the video with two segments (question: 5s, answer: 5s)
    //         await ffmpeg.exec([
    //             "-loop", "1", "-t", "10", "-i", "question.png", // 5s of question only
    //             "-loop", "1", "-t", "5", "-i", "answer.png",   // 5s of question + answer

    //             // Combine them
    //             "-filter_complex", "[0:v:0][1:v:0]concat=n=2:v=1:a=0[out]",
    //             // "-filter_complex", "[0:v][1:v]xfade=transition=fade:duration=2:offset=5[out]",

    //             "-map", "[out]",

    //             // Output settings
    //             "-c:v", "libx264",
    //             "-pix_fmt", "yuv420p",
    //             "output.mp4",
    //         ]);

    //         // Step 9: Read the generated video file
    //         const videoData = await ffmpeg.readFile("output.mp4");

    //         if (!videoData || videoData.length === 0) {
    //             throw new Error("Generated video file is empty or not found");
    //         }

    //         // Step 10: Create a downloadable URL for the video
    //         const videoBlob = new Blob([videoData.buffer], { type: "video/mp4" });
    //         const videoUrl = URL.createObjectURL(videoBlob);

    //         // Step 11: Trigger the download
    //         saveAs(videoUrl, `question-${question.id}.mp4`);
    //         alert("Video downloaded successfully!");

    //     } catch (error) {
    //         console.error("Error generating video:", error);
    //         alert("An error occurred while generating the video.");
    //     } finally {
    //         setIsProcessing(false);
    //     }
    // };


    useEffect(() => {
        let timer;
        if (progress < 100 && isProcessing) {
            timer = setInterval(() => {
                setProgress((prev) => prev + 1);
            }, 100);
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [progress, isProcessing]);

    //WITH whammy video library
    // const handleDownloadVideo = async () => {
    //     setIsProcessing(true);

    //     const element = document.getElementById(`share-question-${question.id}`);
    //     if (!element) {
    //         console.error("Element not found");
    //         setIsProcessing(false);
    //         return;
    //     }

    //     const { width, height } = element.getBoundingClientRect();
    //     const aspectRatio = width / height;

    //     const canvasWidth = 300; // Target video width
    //     const canvasHeight = canvasWidth / aspectRatio; // Maintain aspect ratio

    //     try {
    //         // Generate the first image (question only)
    //         const questionDataUrl = await toPng(element, {
    //             quality: 1,
    //             canvasWidth,
    //             canvasHeight,
    //         });

    //         // Highlight the correct answer dynamically
    //         const options = element.querySelectorAll("li");
    //         options.forEach((optionElement) => {
    //             const option = optionElement.textContent.trim();
    //             if (option === question.correctAnswer) {
    //                 optionElement.style.backgroundColor = "lightgreen";
    //                 optionElement.style.color = "white";
    //             }
    //         });

    //         // Generate the second image (with highlighted answer)
    //         const answerDataUrl = await toPng(element, {
    //             quality: 1,
    //             canvasWidth,
    //             canvasHeight,
    //         });

    //         // Reset styles after capturing
    //         options.forEach((optionElement) => {
    //             optionElement.style.backgroundColor = "";
    //             optionElement.style.color = "";
    //         });

    //         // Convert DataURLs to WebP (if needed by Whammy)
    //         const convertToWebP = (dataUrl) => {
    //             return new Promise((resolve) => {
    //                 const img = new Image();
    //                 img.src = dataUrl;
    //                 img.onload = () => {
    //                     const canvas = document.createElement("canvas");
    //                     canvas.width = img.width;
    //                     canvas.height = img.height;
    //                     const ctx = canvas.getContext("2d");
    //                     ctx.drawImage(img, 0, 0);
    //                     resolve(canvas.toDataURL("image/webp"));
    //                 };
    //             });
    //         };

    //         const questionWebP = await convertToWebP(questionDataUrl);
    //         const answerWebP = await convertToWebP(answerDataUrl);

    //         // Create a Whammy video instance
    //         const video = new Whammy.Video(10); // 10 FPS

    //         // Add frames to the video
    //         for (let i = 0; i < 100; i++) {
    //             video.add(questionWebP);
    //         }
    //         for (let i = 0; i < 50; i++) {
    //             video.add(answerWebP);
    //         }


    //         // Compile the video
    //         video.compile(false, (output) => {
    //             const videoBlob = new Blob([output], { type: "video/webm" });
    //             const videoUrl = URL.createObjectURL(videoBlob);

    //             // Open the video in a new window 
    //             // window.open(videoUrl);
    //             // Trigger download

    //             const link = document.createElement("a");
    //             link.href = videoUrl;
    //             link.download = `question-${question.id}.webm`;
    //             link.textContent = "Download";
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);

    //             alert("Video downloaded successfully!");
    //             setIsProcessing(false);
    //         });

    //     } catch (error) {
    //         console.error("Error generating video:", error);
    //         setIsProcessing(false);
    //     }
    // };

    //Progress working here.
    const handleDownloadVideo = async () => {
        setIsProcessing(true);
        setProgress(0);

        const element = document.getElementById(`share-question-${question.id}`);
        if (!element) {
            console.error("Element not found");
            setIsProcessing(false);
            return;
        }

        const { width, height } = element.getBoundingClientRect();
        const aspectRatio = width / height;

        const canvasWidth = 300; // Target video width
        const canvasHeight = canvasWidth / aspectRatio; // Maintain aspect ratio

        try {
            // Convert DataURLs to WebP (if needed by Whammy)
            const convertToWebP = (dataUrl) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = dataUrl;
                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        canvas.width = img.width;
                        canvas.height = img.height;
                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);
                        resolve(canvas.toDataURL("image/webp"));
                    };
                });
            };

            const captureIntervalFrames = async (element, interval) => {
                const frames = [];
                for (let i = 0; i <= 100; i += interval) {
                    setProgress(i);
                    await new Promise(resolve => setTimeout(resolve, 100)); // 1 second per interval
                    const dataUrl = await toPng(element, { quality: 1, canvasWidth, canvasHeight });
                    const webP = await convertToWebP(dataUrl);
                    frames.push(webP);
                }
                return frames;
            };

            // Capture frames with progress bar updating
            const frames = await captureIntervalFrames(element, 10);

            // Highlight the correct answer dynamically
            const options = element.querySelectorAll("li");
            options.forEach((optionElement) => {
                const option = optionElement.textContent.trim();
                if (option === question.correctAnswer) {
                    optionElement.style.backgroundColor = "lightgreen";
                    optionElement.style.color = "white";
                }
            });

            // Generate the second image (with highlighted answer)
            const answerDataUrl = await toPng(element, { quality: 1, canvasWidth, canvasHeight });
            const answerWebP = await convertToWebP(answerDataUrl);

            // Create a Whammy video instance
            const video = new Whammy.Video(10); // 10 FPS

            // Add frames to the video
            frames.forEach(frame => {
                for (let i = 0; i < 10; i++) {
                    video.add(frame);
                }
            });

            // Add the answer frame for the last 5 seconds
            for (let i = 0; i < 50; i++) {
                video.add(answerWebP);
            }

            // Compile video
            video.compile(false, (output) => {
                const videoBlob = new Blob([output], { type: "video/webm" });
                const videoUrl = URL.createObjectURL(videoBlob);

                const link = document.createElement("a");
                link.href = videoUrl;
                link.download = `question-${question.id}.webm`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setProgress(100); // Done
                alert("Video downloaded successfully!");
                setIsProcessing(false);
            });

        } catch (error) {
            console.error("Error generating video:", error);
            setProgress(0);
            setIsProcessing(false);
        }
    };




    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionClick = (questionId, option) => {
        setSelectedOption(option);
    };

    const getOptionStyle = (selectedOption, correctAnswer, option) => {
        if (option === correctAnswer && selectedOption === option) {
            return { backgroundColor: 'lightgreen', color: 'black' }; // Correctly selected
        }
        if (option === selectedOption && option !== correctAnswer) {
            return { backgroundColor: 'lightcoral', color: 'black' }; // Incorrectly selected
        }
        return {}; // Default style
    };

    return (
        <>
            {/* Overlay for modal */}
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(5px)",
                    zIndex: 999,
                }}
            ></div>
            <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                    background: darkMode ? "#222" : "white",
                    color: darkMode ? "white" : "black",
                    padding: "20px",
                    borderRadius: "10px",
                    maxWidth: "90%",
                    width: "600px",
                    maxHeight: "90%", // Constrain modal height
                    overflowY: "auto", // Enable vertical scrolling
                }}
            >
                <h3>Generate and Download Video</h3>
                <div
                    id={`share-question-${question.id}`}
                    style={{
                        background: darkMode ? "#333" : "white",
                        color: darkMode ? "white" : "black",
                        border: darkMode ? "2px solid #555" : "2px solid #000",
                        borderRadius: "10px",
                        padding: "20px",
                        textAlign: "center",
                        margin: "0 auto",
                    }}
                >
                    <div style={{
                        marginBottom: "20px", border: darkMode ? "1px solid #555" : "1px solid #000", borderRadius: "10px", padding: "20px"
                    }}>

                        <h2 style={{ fontSize: "20px", marginBottom: "10px" }}>
                            {question.question}
                            <span style={{ fontWeight: "bold" }}> {question?.exam}</span>
                        </h2>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                            {question.options.map((option, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleOptionClick(question.id, option)}
                                    style={{
                                        padding: "10px",
                                        margin: "5px 0",
                                        cursor: "pointer",
                                        border: darkMode ? "1px solid #666" : "1px solid #ddd",
                                        borderRadius: "5px",
                                        ...getOptionStyle(selectedOption, question.correctAnswer, option),
                                    }}
                                >
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {/* <span style={{ fontSize: "12px" }}>Please find the answer at the end.</span> */}
                        <div style={{ marginTop: "10px" }}>
                            <div style={{
                                width: "100%",
                                background: darkMode ? "#444" : "#ddd",
                                borderRadius: "5px",
                                overflow: "hidden"
                            }}>
                                <div
                                    style={{
                                        width: `${progress}%`,
                                        height: "10px",
                                        background: "lightgreen",
                                        transition: "width 0.2s ease-in-out",
                                    }}
                                ></div>
                            </div>
                        </div>

                    </div>
                    <footer
                        style={{
                            position: "relative",
                            bottom: "10px",
                            background: darkMode ? "#555" : "#f1f1f1",
                            color: darkMode ? "white" : "black",
                            padding: "5px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <p style={{ fontSize: "14px", margin: 0 }}>© 2024 SSCVIDYA.IN</p>
                    </footer>
                </div>

                <button
                    onClick={handleDownloadVideo}
                    className="button"
                    style={{ marginTop: "10px" }}
                    disabled={isProcessing}
                >
                    {isProcessing ? "Processing..." : "Download Video"}
                </button>
                <button
                    onClick={onClose}
                    className="button"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                >
                    Close
                </button>
            </div>
        </>
    );
};

export default SharePopupWithVideo;

// import React, { useContext, useState } from "react";
// import { toPng } from "html-to-image";
// import { saveAs } from "file-saver";
// import { FFmpeg } from "@ffmpeg/ffmpeg";
// import { themeContext } from "../Context";

// const SharePopupWithVideo = ({ question, onClose }) => {
//     const theme = useContext(themeContext);
//     const darkMode = theme.state.darkMode;
//     const [isProcessing, setIsProcessing] = useState(false);

//     const ffmpeg = new FFmpeg();

//     React.useEffect(() => {
//         document.body.style.overflow = "hidden";
//         return () => {
//             document.body.style.overflow = "auto";
//         };
//     }, []);

//     const handleDownloadVideo = async () => {
//         const element = document.getElementById(`share-question-${question.id}`);
//         if (!element) {
//             console.error("Element not found");
//             return;
//         }

//         setIsProcessing(true);

//         try {
//             // Step 1: Generate an image of the div content
//             const dataUrl = await toPng(element, {
//                 quality: 1,
//                 canvasWidth: 600,
//                 canvasHeight: 300,
//             });

//             // Convert the DataURL to a Blob
//             const imageBlob = await fetch(dataUrl).then((res) => res.blob());
//             const imageFile = new File([imageBlob], "frame.png", { type: "image/png" });

//             // Step 2: Initialize FFmpeg
//             await ffmpeg.load();

//             // Step 3: Write the image file to FFmpeg
//             await ffmpeg.writeFile("frame.png", new Uint8Array(await imageFile.arrayBuffer()));

//             // Step 4: Create the video
//             await ffmpeg.exec([
//                 "-loop",
//                 "1", // Use the image as a frame
//                 "-i",
//                 "frame.png", // Input file
//                 "-c:v",
//                 "libx264", // Codec
//                 "-t",
//                 "5", // Video duration in seconds
//                 "-pix_fmt",
//                 "yuv420p", // Pixel format for compatibility
//                 "output.mp4",
//             ]);

//             // Step 5: Read the generated video file
//             const videoData = await ffmpeg.readFile("output.mp4");

//             // Step 6: Create a downloadable URL for the video
//             const videoBlob = new Blob([videoData.buffer], { type: "video/mp4" });
//             const videoUrl = URL.createObjectURL(videoBlob);

//             // Step 7: Trigger the download
//             saveAs(videoUrl, `question-${question.id}.mp4`);
//             alert("Video downloaded successfully!");
//         } catch (error) {
//             console.error("Error generating video:", error);
//             alert("An error occurred while generating the video.");
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     return (
//         <>
//             {/* Overlay for modal */}
//             <div
//                 style={{
//                     position: "fixed",
//                     top: 0,
//                     left: 0,
//                     width: "100%",
//                     height: "100%",
//                     background: "rgba(0, 0, 0, 0.5)",
//                     backdropFilter: "blur(5px)",
//                     zIndex: 999,
//                 }}
//             ></div>
//             <div
//                 style={{
//                     position: "fixed",
//                     top: "50%",
//                     left: "50%",
//                     transform: "translate(-50%, -50%)",
//                     zIndex: 1000,
//                     background: darkMode ? "#222" : "white",
//                     color: darkMode ? "white" : "black",
//                     padding: "20px",
//                     borderRadius: "10px",
//                     maxWidth: "90%",
//                     width: "600px",
//                     overflowY: "auto",
//                 }}
//             >
//                 <h3>Generate and Download Video</h3>
//                 <div
//                     id={`share-question-${question.id}`}
//                     style={{
//                         background: darkMode ? "#333" : "white",
//                         color: darkMode ? "white" : "black",
//                         border: "2px solid",
//                         borderRadius: "10px",
//                         padding: "20px",
//                         textAlign: "center",
//                     }}
//                 >
//                     <h2>{question.question}</h2>
//                     <ul style={{ listStyleType: "none", padding: 0 }}>
//                         {question.options.map((option, index) => (
//                             <li key={index} style={{ padding: "10px", margin: "5px 0" }}>
//                                 {option}
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//                 <button
//                     onClick={handleDownloadVideo}
//                     className="button"
//                     style={{ marginTop: "10px" }}
//                     disabled={isProcessing}
//                 >
//                     {isProcessing ? "Processing..." : "Download Video"}
//                 </button>
//                 <button
//                     onClick={onClose}
//                     className="button"
//                     style={{ marginTop: "10px", marginLeft: "10px" }}
//                 >
//                     Close
//                 </button>
//             </div>
//         </>
//     );
// };

// export default SharePopupWithVideo;







