import quizzes from "./quizzes.json";
import sscmts2024 from "./sscmts2024.json"
import polity from "./polity.json"
import economics from "./economics.json";
import history from "./history.json";
import geography from "./geography.json";
import current from "./current.json";
import physics from "./physics.json";
import chemistry from "./chemistry.json";
import biology from "./biology.json";
import artsCulture from "./artsCulture.json";

const allData = [
    ...quizzes.categories, sscmts2024, polity, economics, history, geography, current, physics, chemistry, biology, artsCulture
]

export default allData;
