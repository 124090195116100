import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

// Save quiz attempt data
export const saveQuizAttempt = async (userId, quizType, quizSet, attemptData) => {
    try {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
            // If user data exists, update quiz data
            await updateDoc(userDocRef, {
                [`quizAttempts.${quizType}.${quizSet}`]: attemptData,
            });
        } else {
            // If user data doesn't exist, create new data
            await setDoc(userDocRef, {
                quizAttempts: {
                    [quizType]: {
                        [quizSet]: attemptData,
                    },
                },
            });
        }
    } catch (error) {
        console.error("Error saving quiz attempt: ", error);
    }
};
