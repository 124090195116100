// import { Helmet } from "react-helmet";

// const QuizCategorySEO = () => (
//     <Helmet>
//         <title>SSCVIDYA: SSC CGL, CHSL, MTS, RRB Topic-wise Practice MCQs</title>
//         <meta
//             name="description"
//             content="Discover topic-wise practice with sscvidya quizzes for SSC CGL, CHSL, MTS, and RRB exams. Boost your preparation with GK, Current Affairs, Geography, Biology, and Static GK quizzes tailored to enhance your exam success rate."
//         />
//         <meta
//             name="keywords"
//             content="ssc cgl quiz, sscvidya, ssc vidya, vidya quiz, sscvidya gk quizzes, current affairs quiz, ssc mcq practice, geography quiz, biology quiz, static gk quiz, ssc exam preparation, competitive exam quizzes, SSC CHSL MCQs, RRB MTS practice, quiz for SSC aspirants"
//         />
//         <meta
//             property="og:title"
//             content="SSCVIDYA: SSC CGL, CHSL, MTS, RRB Topic-wise Practice MCQs"
//         />
//         <meta
//             property="og:description"
//             content="Explore a variety of topic-wise quizzes to prepare effectively for SSC CGL, CHSL, MTS, and RRB exams. Sharpen your skills with practice questions on GK, Current Affairs, and more."
//         />
//         <meta
//             property="og:type"
//             content="website"
//         />
//         <meta
//             property="og:url"
//             content="https://sscvidya.in/quizCategory"
//         />
//         <meta
//             property="og:image"
//             content="https://sscvidya.in/assets/quiz-thumbnail.jpg"
//         />
//         <meta
//             name="twitter:card"
//             content="summary_large_image"
//         />
//         <meta
//             name="twitter:title"
//             content="SSCVIDYA: SSC CGL, CHSL, MTS, RRB Topic-wise Practice MCQs"
//         />
//         <meta
//             name="twitter:description"
//             content="Prepare for SSC and other competitive exams with our dynamic topic-wise quizzes. Get better at GK, Current Affairs, and more."
//         />
//         <meta
//             name="twitter:image"
//             content="https://sscvidya.in/assets/quiz-thumbnail.jpg"
//         />
//         <link
//             rel="canonical"
//             href="https://sscvidya.in/quizCategory"
//         />
//     </Helmet>

// );

// export default QuizCategorySEO;

import React from "react";
import { Helmet } from "react-helmet";

// const QuizCategorySEO = ({ category, imageURL, quizzes }) => {
//     const siteName = "SSC Vidya";

//     // Define defaults
//     const defaultCategory = "General Knowledge";
//     const defaultImageURL = "https://example.com/default-image.jpg"; // Replace with a fallback image URL
//     const defaultKeywords = "SSCVidya, ssc vidya, sscvidya.in, quizzes, education, SSC preparation, online tests, learning";

//     // Use default values when props are missing
//     const finalCategory = category || defaultCategory;
//     const finalImageURL = imageURL || defaultImageURL;
//     const pageTitle = `${finalCategory} | ${siteName}`;
//     const pageDescription = `Explore quizzes and resources for ${finalCategory}. Boost your preparation with high-quality content.`;
//     return (
//         <Helmet>
//             {/* Dynamic or default title and description */}
//             <title>{pageTitle}</title>
//             <meta name="description" content={pageDescription} />

//             {/* Open Graph metadata */}
//             <meta property="og:title" content={pageTitle} />
//             <meta property="og:description" content={pageDescription} />
//             <meta property="og:image" content={finalImageURL} />
//             <meta property="og:url" content={window.location.href} />

//             {/* Twitter Card metadata */}
//             <meta name="twitter:card" content="summary_large_image" />

//             {/* SEO Keywords */}
//             <meta
//                 name="keywords"
//                 content={category
//                     ? `${category}, quizzes, ${category} tests, SSC ${category}, ${category} preparation`
//                     : defaultKeywords}
//             />
//         </Helmet>
//     );
// };

const QuizCategorySEO = ({ category, imageURL, quizzes }) => {

    // Define defaults
    const defaultCategory = "General Knowledge";
    const defaultImageURL = "https://example.com/default-image.jpg"; // Replace with a fallback image URL
    const defaultKeywords = "SSCVidya, ssc vidya, sscvidya.in, quizzes, education, SSC preparation, online tests, learning";

    // Use default values when props are missing
    const finalCategory = category?.replace(/-/g, ' ')?.toUpperCase() || defaultCategory;
    const finalImageURL = imageURL || defaultImageURL;

    // Dynamic title and description
    const pageTitle = `${finalCategory} | SSC Vidya`;
    const pageDescription = `Explore quizzes and resources for ${finalCategory}. Boost your preparation with high-quality content.`;

    // console.log("Quizzes", quizzes);

    return (
        <Helmet>
            {/* Page Title */}
            <title>{pageTitle}</title>

            {/* Meta Description */}
            <meta name="description" content={pageDescription} />

            {/* Keywords */}
            <meta
                name="keywords"
                content={category
                    ? `${category}, quizzes, ${category} tests, SSC ${category}, ${category} preparation`
                    : defaultKeywords}
            />

            {/* Open Graph Tags for Social Sharing */}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:image" content={finalImageURL} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />

            {/* Canonical URL for SEO */}
            <link rel="canonical" href={window.location.href} />

            {/* Structured Data for SEO */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "name": "SSC Vidya",
                    "url": "https://sscvidya.in",
                    "logo": "https://sscvidya.in/logo.png",
                    "description": "SSC Vidya offers quizzes, topicwise MCQs, and mock tests for SSC CGL, CHSL, MTS, and RRB exams.",
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://sscvidya.in/?q={search_term}",
                        "query-input": "required name=search_term"
                    },
                    "sameAs": [
                        "https://facebook.com/sscvidya",
                        "https://twitter.com/sscvidya",
                        "https://instagram.com/sscvidya"
                    ]
                })}
            </script>
        </Helmet>
    );
};

export default QuizCategorySEO;