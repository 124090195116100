import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
// import quizData from "../data/quizzes.json"; // Import your JSON data
import allData from "../data/allData";// Import your JSON data
import { themeContext } from '../Context';
import { auth } from '../firebase/firebaseConfig';
import QuizCategorySEO from '../utilities/SEO/QuizCategorySEO';
import SharePopup from '../utilities/SharePopup';
import SharePopupWithVideo from '../utilities/SharePopupWithVideo';
import { saveQuizAttempt } from '../utilities/SaveQuizAttempt';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase/firebaseConfig';
function DummyPage() {
    const { quizType, quizSet } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedOptions, setSelectedOptions] = useState({});
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);
    const [unattempted, setUnattempted] = useState(0);
    const [user, setUser] = useState(null);

    // State to Manage Popup Visibility
    const [showPopup, setShowPopup] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [activeVideoQuestion, setActiveVideoQuestion] = useState(null);

    // State to Manage Popup Visibility
    const [showVideoPopup, setShowVideoPopup] = useState(false);

    // Handler to Open Popup
    const handleShareClick = (question) => {
        setActiveQuestion(question);
        setShowPopup(true);
        // console.log("question", question)
    };

    // Handler to Open Popup
    const handleVideoShareClick = (question) => {
        setActiveVideoQuestion(question);
        setShowVideoPopup(true);
        // console.log("question", question)
    };




    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    // Effect to track the authentication state
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(currentUser => {
            setUser(currentUser);
        });
        return () => unsubscribe(); // Unsubscribe on component unmount
    }, []);



    // Determine if we should display the header based on the current path
    const showHeading = location.pathname === `/quizCategory` || location.pathname.endsWith('/quizcategory/');
    // const showSubHeading = location.pathname.includes('/quizCategory/');
    const showSubHeading = location.pathname.startsWith('/quizCategory/') && location.pathname !== '/quizCategory/';
    // const showHeading = !showSubHeading && (location.pathname === '/quizCategory' || location.pathname === '/quizCategory/');



    // Find the quizzes for the selected quiz type and set
    // const selectedCategory = quizData.categories.find(category => category.category === quizType);
    // console.log("selectedCategory", allData);
    // console.log("selectedCategory2", quizData.categories);

    const selectedCategory = allData.find(category => category.category === quizType);
    const selectedSet = selectedCategory && quizSet !== undefined ? selectedCategory.sets[parseInt(quizSet)] : null;
    // console.log("selectedCategory2", selectedCategory);

    // Redirect if quiz set requires authentication and the user is not logged in
    useEffect(() => {
        if (selectedSet && selectedSet.authRequired && !user) {
            navigate('/login'); // Redirect to login page
        }
    }, [selectedSet, user, navigate]);


    // Handle option selection using question ID
    const handleOptionClick = (questionId, option) => {
        setSelectedOptions({
            ...selectedOptions,
            [questionId]: option,
        });
    };

    const shuffledQuestions = useMemo(() => {
        return selectedSet
            ? selectedSet.quizzes.map(question => ({
                ...question,
                options: [...question.options].sort(() => Math.random() - 0.5),
            }))
            : [];
    }, [selectedSet]);

    // useEffect(() => {
    //     console.log("shuffledQuestions", shuffledQuestions);
    // }, [shuffledQuestions]); // This logs only once on mount

    // Calculate the user's score
    // const calculateScore = () => {
    //     let correctAnswers = 0;
    //     let unattemptedCount = 0;

    //     const questions = selectedSet ? selectedSet.quizzes : [];
    //     questions.forEach((question) => {
    //         const userAnswer = selectedOptions[question.id];
    //         if (userAnswer === question.correctAnswer) {
    //             correctAnswers += 1;
    //         } else if (!userAnswer) {
    //             unattemptedCount += 1;
    //         }
    //     });

    //     setScore(correctAnswers);
    //     setUnattempted(unattemptedCount);
    //     setShowScore(true);
    // };
    const calculateScore = async () => {
        let correctAnswers = 0;
        let unattemptedCount = 0;

        const questions = selectedSet ? selectedSet.quizzes : [];
        questions.forEach((question) => {
            const userAnswer = selectedOptions[question.id];
            if (userAnswer === question.correctAnswer) {
                correctAnswers += 1;
            } else if (!userAnswer) {
                unattemptedCount += 1;
            }
        });

        const attemptData = {
            score: correctAnswers,
            unattempted: unattemptedCount,
            totalQuestions: questions.length,
            timestamp: new Date().toISOString(),
        };

        setScore(correctAnswers);
        setUnattempted(unattemptedCount);
        setShowScore(true);

        // Save to Firestore
        if (user) {
            await saveQuizAttempt(user.uid, quizType, quizSet, attemptData);
            alert("Quiz attempt saved successfully!");
        } else {
            alert("Please log in to save your quiz progress.");
        }

    };

    const [updatedCategory, setUpdatedCategory] = useState(selectedCategory);

    useEffect(() => {
        const fetchUserQuizAttempts = async () => {
            if (user) {
                const userDocRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const quizAttempts = userDoc.data().quizAttempts || {};

                    // Create a new copy of selectedCategory
                    const updatedSets = selectedCategory?.sets.map((set, index) => {
                        if (quizAttempts[quizType]?.[index]) {
                            return {
                                ...set,
                                attempted: true,
                                lastScore: quizAttempts[quizType][index].score,
                            };
                        }
                        return set;
                    });

                    // Update the state with the new copy
                    setUpdatedCategory({
                        ...selectedCategory,
                        sets: updatedSets,
                    });
                }
            }
        };

        fetchUserQuizAttempts();
    }, [user, quizType, selectedCategory]);


    // Style for answers
    const getOptionStyle = (questionId, option) => {
        if (!selectedSet) return {};
        const question = selectedSet.quizzes.find(q => q.id === questionId);
        const correctAnswer = question.correctAnswer;
        const selectedOption = selectedOptions[questionId];

        if (option === correctAnswer && selectedOption === option) {
            return { backgroundColor: 'lightgreen' };
        }
        if (option === selectedOption && option !== correctAnswer) {
            return { backgroundColor: 'lightcoral' };
        }
        if (selectedOption && correctAnswer === option) {
            return { backgroundColor: 'lightgreen' };
        }
        return { backgroundColor: darkMode ? "rgb(128 128 128 / 42%)" : '#f0f0f0' };
    };

    // Handle Refresh
    const handleRefresh = () => {
        navigate('/quizCategory');
        setSelectedOptions({});
        setShowScore(false);
        setUnattempted(0);
    };

    return (
        <div style={{ padding: '20px', minHeight: "100vh", overflowY: 'auto' }}>
            {/* Helmet for setting the head elements */}
            {selectedCategory && (
                <QuizCategorySEO
                    category={selectedCategory?.category}
                    imageURL={selectedCategory?.imageURL}
                    quizzes={selectedCategory?.sets.flatMap((set) => set.quizzes)}
                />
            )}

            {/* Conditional Rendering of the Heading */}
            {showHeading && (
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>SSC CGL Exam </span>
                    <span>Practice MCQ Quiz </span>
                    <span>Topic-Wise Questions for </span>
                    <span>CGL, MTS, CHSL</span>
                </div>
            )}
            {/* <h1>{quizType ? `${quizType.charAt(0).toUpperCase() + quizType.slice(1)} Quiz` : 'Select a Quiz:'}</h1> */}
            {showSubHeading && (
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>Practice MCQ Quiz </span>
                    <span>{quizType ? `${quizType.replace(/-/g, ' ').toUpperCase()}` : 'Select a Quiz:'}</span>
                    <span> Topic-Wise Questions.</span>
                </div>
            )}
            {/* Step 1: Quiz Type Selection */}
            {!quizType && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px', flexWrap: "wrap" }}>

                    {allData.map((quiz) => (
                        <div
                            key={quiz.category}
                            onClick={() => navigate(`/quizCategory/${quiz.category}`)}
                            style={{
                                cursor: 'pointer',
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                width: '200px',
                                textAlign: 'center',
                                padding: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <img
                                src={quiz.imageURL}
                                alt={quiz.category}
                                style={{ width: '100%', borderRadius: '10px' }}
                            />
                            <p style={{ fontWeight: 'bold', marginTop: '10px' }}>{quiz.category.replace(/-/g, ' ').toUpperCase()}</p>
                        </div>
                    ))}
                </div>
            )}


            {/* Step 2: Quiz Set Selection */}
            {quizType && quizSet === undefined && selectedCategory && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px', flexWrap: "wrap" }}>
                    <QuizCategorySEO
                        category={selectedCategory?.category}
                        imageURL={selectedCategory?.imageURL}
                        quizzes={selectedCategory?.sets.flatMap((set) => set.quizzes)}
                    />
                    {selectedCategory.sets.map((set, index) => (

                        <div
                            key={set.set}
                            // onClick={() => navigate(`/quizCategory/${quizType}/${index}`)}
                            onClick={() => {
                                if (set.authRequired && !user) {
                                    navigate('/login');
                                } else {
                                    navigate(`/quizCategory/${quizType}/${index}`);
                                }
                            }}
                            style={{
                                cursor: 'pointer',
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                width: '200px',
                                textAlign: 'center',
                                padding: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <p style={{ fontWeight: 'bold', marginTop: '10px' }}>
                                {set.title} (Questions: {set.quizzes.length})
                            </p>
                            {/* Display login message if auth is required and user is not logged in */}
                            {set.authRequired && !user && (
                                <p style={{ color: 'red', fontSize: '0.9em', marginTop: '5px' }}>
                                    Please login to access.
                                </p>
                            )}
                            <p>
                                {
                                    user &&
                                    updatedCategory?.sets &&
                                    updatedCategory?.sets[index] &&
                                    updatedCategory?.sets[index].attempted && (
                                        <p>
                                            <span style={{ color: "green", fontSize: "14px" }}>
                                                Completed! Score: <span style={{ color: "green", fontSize: "15px", fontWeight: "bold" }}>{updatedCategory?.sets[index]?.lastScore}</span>
                                            </span>
                                        </p>
                                    )
                                }
                            </p>
                            {/* Completed Icon shows if the set has been attempted of not. */}
                        </div>
                    ))}
                </div>
            )}

            {/* Step 3: Display Quiz Questions */}
            {selectedSet && (
                <div>
                    {shuffledQuestions.map((question, index) => (
                        <div key={question.id} id={`question-${question.id}`} style={{ marginBottom: '20px' }}>
                            <h3>{index + 1}. {question.question} <span style={{ fontWeight: "bold" }}>{question?.exam}</span></h3>
                            <ul>
                                {question.options
                                    .map((option) => (
                                        <li
                                            key={option}
                                            onClick={() => handleOptionClick(question.id, option)}
                                            style={{
                                                padding: '10px',
                                                margin: '5px 0',
                                                cursor: 'pointer', ...getOptionStyle(question.id, option)
                                            }}
                                        >
                                            {option}
                                        </li>
                                    ))}
                            </ul>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <button
                                    onClick={() => handleShareClick(question)}
                                    className="button"
                                    style={{ fontSize: "small" }}

                                >
                                    Share or Save
                                </button>
                                <button
                                    onClick={() => handleVideoShareClick(question)}
                                    className="button"
                                    style={{ marginLeft: "5px", fontSize: "small" }}
                                >
                                    Save as Video
                                </button>
                            </div>

                        </div>
                    ))}
                    <button className="button" onClick={calculateScore}>Submit</button>
                    {showScore && (
                        <div>
                            <h2>Your Score: {score} out of {selectedSet.quizzes.length}</h2>
                            <h2>Unattempted: {unattempted}</h2>
                            <button className="button" onClick={handleRefresh}>Refresh Quiz</button>
                        </div>
                    )}
                    {/* Render the SharePopup */}
                    {showPopup && activeQuestion && (
                        <SharePopup
                            question={activeQuestion}
                            onClose={() => setShowPopup(false)}
                        />
                    )}
                    {/* Render the videoSharePopup */}
                    {showVideoPopup && activeVideoQuestion && (
                        <SharePopupWithVideo
                            question={activeVideoQuestion}
                            onClose={() => setShowVideoPopup(false)}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default DummyPage;
